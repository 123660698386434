
export default function Header() {
    let classNameText = "text-center text-4xl font-mukta text-primary";

    return(
        <div className={"p-10"}>
            <h1 className={classNameText}>Max Hoecker</h1>
        </div>

    )
}

